import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { table_fields} from "../commonDynmicForms/table_fields"
import { constants } from "../constants";

export default {
  fillable_by:FillableBy.Both,
  title:
    "KPS Normothermic Regional Perfusion (NRP) Perfusionist (CCP) Competency",
  sections: [
    // {...employeeinfo},
    {
      title: "COGNITIVE NRP TRAINING AND COMPETENCY DEMONSTRATION",
      type: "table",
      heads: constants.tableHeads,
      rows: [
        "Describe purpose of NRP and the hallmark events that define it",
        "Detail the Donor OR setup process",
        "Explain cannulation steps for: A-NRP, TA-NRP, Hybrid A-NRP",
        "Identify all components of NRP circuit",
        "Operation of NRP equipment (see equipment-specific competency)",
        "Setup NRP equipment and disposables",
        "Prime NRP circuit",
        "Management of the NRP circuit",
        "Metabolic management on NRP",
        "Management of blended technology cases (i.e., SRR & NRP, Organ machine perfusion & NRP)",
        "NRP case documentation in Keyport",
        "Equipment breakdown and cleaning"
      ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
    },
    {...evaluationCheckList},
    {
      title: "Signatures",
      type: "signature",
      fields: getSignatures(FillableBy.Both)
    },
  ]
}